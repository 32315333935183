import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

class Footer extends Component {
    render() {
        return <div className="w-100 m-auto bg-primary text-white p-2">
            <Container>
                <Row>
                    <Col sm={6} className="center">
                        &copy; Copyright 2022
                    </Col>
                    <Col sm={6} className="center">
                        <a href="/policy" target="_blank" className="text-white">Informativa per la privacy</a>
                    </Col>
                </Row>
            </Container>
        </div>
    }
}

export default Footer;