import React, { Component } from 'react';
import Section from '../../../components/Section';

class Link extends Component{
    render(){
        return <a href={this.props.link} target="_blank" rel="noreferrer noopener">{this.props.title}</a>
    }
}

class Pages extends Component{
    links = [
        ["https://cup.apss.tn.it/#/walkthrough", "CUP online"],
        ["https://trec.trentinosalute.net/home", "TREC cartella clinica del cittadino"],
        ["https://www.farmaciediturno.org/comune.asp?cod=22022", "Farmacia di turno a Borgo Valsugana"],
        ["https://www.farmaciediturno.org/provincia.asp?cod=22", "Farmacia di turno in provincia"],
        ["https://cup.apss.tn.it/webportal/vaccinocovid/", "Prenotazioni vaccino covid"],
        ["https://www.apss.tn.it/Novita/Notizie/Covid-19-le-FAQ", "Coronavirus domande e risposte (sito provinciale)"],
        ["https://www.salute.gov.it/portale/nuovocoronavirus/archivioFaqNuovoCoronavirus.jsp", "Coronavirus domande e risposte (sito nazionale)"]
    ]

    render(){
        return <Section
            sectionTitle="Pagine utili"
            sectionId="pages"
            sectionBackgroundClass="section-background-5">
                <div className="links mt-5 mb-5">
                    {this.links.map((l, i) => { return <Link key={i} link={l[0]} title={l[1]}/>})}
                </div>
        </Section>
    }
}

export default Pages;