import '../App.scss';
import Header from './structure/Header';
import Content from './structure/Content';
import Footer from './structure/Footer';
import CookieSetter from './structure/CookieSetter';

function App() {
  return <div>
    <CookieSetter/>
    <Header/>
    <Content/>
    <Footer/>
  </div>
}

export default App;
