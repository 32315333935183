import { createSlice } from '@reduxjs/toolkit';

const cookiesSlice = createSlice({
    name: "cookies",
    initialState: {cookieConsent: false},
    reducers: {
        updateCookieConsent(state, action){
            return {...state, "cookieConsent": action.payload};
        }
    }
});

export const {
    updateCookieConsent
} = cookiesSlice.actions;

export default cookiesSlice.reducer;