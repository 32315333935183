import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  TelephoneFill,
  EnvelopeFill,
  GeoAltFill,
  PhoneFill,
} from "react-bootstrap-icons";
import Section from "../../../components/Section";
import { connect } from "react-redux";
import { getCookieConsent } from "../cookiesSelector";

const mapStateToProps = (state) => {
  return {
    cookieConsent: getCookieConsent(state),
  };
};

class Contacts extends Component {
  render() {
    let withCookiesOn = "",
      withCookiesOn2 = "";
    try {
      if (this.props.cookieConsent) {
        withCookiesOn = (
          <Row className="center mt-3">
            <Col className="center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11075.626410253157!2d11.447141588157733!3d46.05296114738596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477890f84cd20e31%3A0x403200523bd1ed1c!2sVia%20Generale%20de%20Medici%20%2C%2016%2C%2038051%20Borgo%20Valsugana%20TN!5e0!3m2!1sit!2sit!4v1648397903398!5m2!1sit!2sit"
                className="map"
                style={{ border: 0 }}
                allowFullScreen=""
                title="Posizione"
                loading="lazy"
              ></iframe>
            </Col>
          </Row>
        );
        withCookiesOn2 = (
          <Row className="center mt-3">
            <Col className="center">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2768.0437113812773!2d11.475437715451982!3d46.07016957911277!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477891b68473b409%3A0xae0bed6779bc573f!2sPiazza%20Maggiore%2C%203%2C%2038050%20Telve%20TN!5e0!3m2!1sit!2sit!4v1650474865645!5m2!1sit!2sit"
                className="map"
                style={{ border: 0 }}
                allowFullScreen=""
                title="Posizione"
                loading="lazy"
              ></iframe>
            </Col>
          </Row>
        );
      }
    } catch {
      console.log("No consent");
    }

    return (
      <Section
        sectionTitle="Contatti"
        sectionId="contacts"
        sectionBackgroundClass="section-background-6"
      >
        <Container fluid className="mt-3 mb-5">
          <Row>
            <Col className="center">
              <div className="icon-text">
                <TelephoneFill /> <p>Segreteria:</p>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="center">
              <a href="tel:0461766025">0461766025</a>
            </Col>
          </Row>
          <Row>
            <Col className="center">
              <div className="icon-text">
                <PhoneFill /> <p>Cellulare:</p>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="center">
              <a href="tel:3773853382">3773853382</a>
            </Col>
          </Row>
          <Row>
            <Col className="center">
              <div className="icon-text">
                <EnvelopeFill className="mr-5" /> <p>E-mail:</p>{" "}
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="center">
              {" "}
              <a href="mailto:ambulatorio.chiaramicheli@gmail.com">
                ambulatorio.chiaramicheli@gmail.com
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="center">
              <div className="icon-text">
                <GeoAltFill />
                <p className="font-weight-bold">
                  Indirizzo ambulatorio Borgo Valsugana:{" "}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="center">
              <p>Via Generale de Medici 1, primo piano, Borgo Valsugana</p>
            </Col>
          </Row>
          <Row className="mb-3">{withCookiesOn}</Row>

          <Row>
            <Col className="center">
              <div className="icon-text">
                <GeoAltFill />
                <p className="font-weight-bold">
                  Indirizzo ambulatorio Telve:{" "}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="center">
              <p>Piazza Maggiore 3/C, Telve</p>
            </Col>
          </Row>
          <Row className="mb-2">{withCookiesOn2}</Row>
        </Container>
      </Section>
    );
  }
}

export default connect(mapStateToProps)(Contacts);
