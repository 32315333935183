import React, { Component } from "react";
import Contacts from "./sections/Contacts";
import NavBar from "./Navbar";
import TimeSchedule from "./sections/timeSchedule/TimeSchedule";
import Pages from "./sections/Pages";
import Requests from "./sections/Requests";
//import News from "./sections/News";

class Content extends Component {
  render() {
    return (
      <div className="w-100">
        <div id="home" />
        <NavBar />
        {/*<News />*/}
        <TimeSchedule />
        <Requests />
        <Contacts />
        <Pages />
      </div>
    );
  }
}

export default Content;
