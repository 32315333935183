import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import "./Section.scss";

class Section extends Component {
    render() {
        let icon = "";
        if (this.props.sectionTitleIcon) {
            let Icon = this.props.sectionTitleIcon;
            icon = <div className="section-icon"><Icon /></div>
        }
        let minHeightDiv = "min-height ";
        if (this.props.noMinHeight) {
            minHeightDiv = "";
        }
        return <div className={"section " + (this.props.sectionBackgroundClass ? this.props.sectionBackgroundClass : "")}>
            <div id={this.props.sectionId} className="section-id" />       {/* id used for smooth scrolling */}
            <div className="section-title p-3">
                <Container className="center">
                    {icon}
                    <h1 className="pb-4">{this.props.sectionTitle}</h1>
                </Container>
            </div>
            <Container className={minHeightDiv + "p-2 pt-4"}>
                {this.props.children}
            </Container>
        </div>;
    }
}

export default Section;