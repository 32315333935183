import React, { Component } from "react";
import { Table } from "react-bootstrap";
import Section from "../../../../components/Section";

import "./timeSchedule.scss";

class TimeSchedule extends Component {
  render() {
    return (
      <Section
        sectionTitle="Orari"
        sectionId="time_schedule"
        sectionBackgroundClass="section-background-1"
      >
        <div className="mt-3 mb-5">
          <h1 className="center mb-5">Modalità di accesso all'ambulatorio</h1>

          <p className="center">
            <span>
              L’accesso all’ambulatorio avviene&nbsp;<b>su appuntamento</b>.
            </span>
          </p>

          <p className="center">
            <span>
              Per consulto telefonico usufruire delle fasce orarie di
              reperibilità telefonica.{" "}
            </span>
          </p>

          <p className="center">
            <span>
              Al di fuori dell’orario di reperibilità, rimango contattabile
              telefonicamente&nbsp;<b>per urgenze</b>.{" "}
            </span>
          </p>

          <p className="center">
            <span>
              Nel caso in cui non riuscissi a rispondere subito, richiamerò
              appena possibile.
            </span>
          </p>

          <Table responsive="md" className="mt-4">
            <thead>
              <tr>
                <th></th>
                <th>
                  Visite su <br />
                  appuntamento
                </th>
                <th>
                  Reperibilità <br />
                  telefonica
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Lunedì</td>
                <td>
                  14:00 - 16:00
                  <br />
                  18:30 - 19:30
                </td>
                <td>13:00 - 14:00</td>
              </tr>
              <tr>
                <td>Martedì</td>
                <td>10:30 - 13:00</td>
                <td>9:30 - 10:30</td>
              </tr>
              <tr>
                <td>Mercoledì</td>
                <td>
                  <p className="p-0 m-0">14:00 - 14:30 (Telve)</p>
                  <p className="p-0 m-0">14:45 - 17:15 (Borgo)</p>
                </td>
                <td>13:00 - 14:00</td>
              </tr>
              <tr>
                <td>Giovedì</td>
                <td>10:30 - 13:00</td>
                <td>9:30 - 10:30</td>
              </tr>
              <tr>
                <td>Venerdì</td>
                <td>10:30 - 13:00</td>
                <td>9:30 - 10:30</td>
              </tr>
            </tbody>
          </Table>

          <p className="center">
            Solo su appuntamento: lunedì 9:00 - 12:00 Castel Ivano
          </p>

          <p className="center mt-5">
            <span>
              Si ricorda agli assistiti che tra le 20 e le 8, nei giorni festivi
              e a partire dalle ore 10 dei giorni prefestivi <br />è attivo il
              servizio di Continuità Assistenziale (Guardia Medica){" "}
            </span>
          </p>

          <p className="center">
            <span>
              Per contattare il servizio, chiamare il numero{" "}
              <a href="tel:116117">116117</a>
            </span>
          </p>
        </div>
      </Section>
    );
  }
}

export default TimeSchedule;
