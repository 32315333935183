import React, { Component } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';

class NavBar extends Component {

    componentDidMount() {
        // adding event listener for smooth scrolling
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });
    }

    render() {
        return <div>
            <div className="nav-min-height" />
            <Navbar bg="primary" expand="lg" fixed="top" className="nav-min-height font-white center w-100" collapseOnSelect>
                <Container className="center m-0">
                    <Navbar.Brand href="#home">Dott.ssa Chiara Micheli</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="max-width">
                        <Nav>
                            {/*<Nav.Link href="#news">Novità</Nav.Link>*/}
                            <Nav.Link href="#time_schedule">Orari</Nav.Link>
                            <Nav.Link href="#requests">Richieste</Nav.Link>
                            <Nav.Link href="#contacts">Contatti</Nav.Link>
                            <Nav.Link href="#pages">Pagine utili</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    }
}

export default NavBar;