import { Component } from 'react';
import { connect } from 'react-redux';
import { updateCookieConsent } from './cookiesSlice';

const mapDispatchToProps = (dispatchEvent) => {
    return {
        updateCookieConsent: (val) => dispatchEvent(updateCookieConsent(val))
    }
}

class CookieSetter extends Component{
    render(){
        window.onConsentUpdate = this.props.updateCookieConsent;
        return "";
    }
}

export default connect(null, mapDispatchToProps)(CookieSetter);